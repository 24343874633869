import { inject, provideAppInitializer } from '@angular/core';
import { NativeClassesService } from '@core/native/services/native-classes.service';
import { NativeEventsService } from '@core/native/services/native-events.service';
import { TokenBackupService } from '@core/native/services/token-backup.service';
import { initialize as initializeSafeArea } from '@capacitor-community/safe-area';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

const nativeClassesInitializer = provideAppInitializer(() => {
    const nativeClassesService = inject(NativeClassesService);
    nativeClassesService.addNativeClasses();
});

const nativeEventsInitializer = provideAppInitializer(() => {
    const nativeEventsService = inject(NativeEventsService);
    nativeEventsService.registerNativeEvents();
});

const nativeBackUpInitializer = provideAppInitializer(async () => {
    const tokenBackupService = inject(TokenBackupService);
    await tokenBackupService.load();
});

const nativeSafeAreaInitializer = provideAppInitializer(() => {
    initializeSafeArea();
});

export const NATIVE_PROVIDERS = [
    nativeClassesInitializer,
    nativeEventsInitializer,
    nativeBackUpInitializer,
    nativeSafeAreaInitializer,
    InAppBrowser
];
