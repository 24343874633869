<a *ngIf="theme?.icon_image" class="cc-sidebar-left-app-icon" (click)="onAppIconClick()">
    <img [src]="theme.icon_image" alt="App Icon" />
</a>

<div class="module-icon-container cc-hide-scrollbar" role="list">
    <ion-item
        *ngFor="let module of modules"
        class="module-icon-item"
        lines="none"
        color="sidebar"
        tooltip-class="cc-sidebar-left-tooltip"
        button
        [ngClass]="[selectedModule === module.id ? 'selected' : '', 'cc-sidebar-left-icon-item-' + module.id]"
        [options]="tooltipOptions"
        [tooltip]="module.name"
        [routerLink]="module.routeCommands"
        (click)="onModuleClick()"
    >
        <div class="module-icon-item-inner">
            <cc-module-icon class="module-icon-item-icon" [module]="module"></cc-module-icon>

            <ion-label *ngIf="showLabels" class="module-icon-item-label ion-text-wrap">
                {{ module.name }}
            </ion-label>
        </div>
    </ion-item>
</div>

<div class="footer">
    <ion-button
        class="ion-no-margin"
        expand="full"
        color="sidebar"
        tooltip-class="cc-sidebar-left-tooltip"
        [options]="tooltipOptions"
        [tooltip]="'SIDEBAR_LEFT_EXPAND_HINT' | translate"
        (click)="onToggleCollapse()"
    >
        <i class="material-icons" slot="icon-only" aria-label="Expand" aria-expanded="false">chevron_right</i>
    </ion-button>

    <ion-button
        *ngIf="portalConfig"
        class="ion-no-margin"
        expand="full"
        color="sidebar"
        tooltip-class="cc-sidebar-left-tooltip"
        [options]="tooltipOptions"
        [tooltip]="('SIDEBAR_LEFT_BACK_TO_PORTAL' | translate) + ' ' + portalConfig.portal_name"
        (click)="onPortalReturn()"
    >
        <i class="material-icons" aria-label="Return to portal">apps</i>
    </ion-button>
</div>
