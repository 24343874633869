import { Component, Input } from '@angular/core';
import { NavigationHistoryService } from '@core/navigation-history/services/navigation-history.service';
import { PortalConfig } from '@features/portal/interfaces/portal-config.interface';
import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonItem,
    IonLabel,
    IonList,
    IonTitle,
    IonToolbar,
    ModalController
} from '@ionic/angular/standalone';
import { ModuleWithRouteCommands } from '@shared/api/models/ModuleWithRouteCommands';
import { NgFor, NgIf } from '@angular/common';
import { RouterLink } from '@angular/router';
import { ModuleIconComponent } from '../module-icon/module-icon.component';
import { ExperimentalSwitchComponent } from '@core/environment/components/experimental-switch/experimental-switch.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'cc-tabbar-more',
    templateUrl: './tabbar-more.component.html',
    styleUrls: ['./tabbar-more.component.scss'],
    imports: [
        NgFor,
        RouterLink,
        ModuleIconComponent,
        NgIf,
        ExperimentalSwitchComponent,
        TranslateModule,
        IonHeader,
        IonToolbar,
        IonTitle,
        IonButtons,
        IonButton,
        IonContent,
        IonList,
        IonItem,
        IonLabel
    ]
})
export class TabbarMoreComponent {
    @Input()
    public selectedModuleId: number;

    @Input()
    public modules: ModuleWithRouteCommands[];

    @Input()
    public portalConfig: PortalConfig;

    constructor(
        private modalController: ModalController,
        private navigationHistoryService: NavigationHistoryService
    ) {}

    public onModuleClick(): void {
        this.navigationHistoryService.forget();
        this.dismiss();
    }

    public portalReturn(): void {
        this.dismiss({ portal: true });
    }

    public openTerms(): void {
        this.dismiss({ terms: true });
    }

    public dismiss(data?: any): void {
        this.modalController.dismiss(data);
    }
}
