import { MeetingBookingConfigModel } from '@features/meeting-booking/store/models/meeting-booking-config.model';
import { BusinessCardSettings } from '@features/user-business-cards/interfaces/business-card-settings.interface';
import { createAction, props } from '@ngrx/store';

const prefix = '[Global Settings] ';

const fetchMeetingBookingConfig = createAction(prefix + 'Fetch meeting config');
const fetchMeetingBookingConfigSuccess = createAction(
    prefix + 'Fetch meeting config success',
    props<{ config: MeetingBookingConfigModel }>()
);
const fetchMeetingBookingConfigFailure = createAction(prefix + 'Fetch business cards config failure');

const fetchUserBusinessCardsConfig = createAction(prefix + 'Fetch business cards config');
const fetchUserBusinessCardsConfigSuccess = createAction(
    prefix + 'Fetch business cards config success',
    props<{ settings: BusinessCardSettings }>()
);
const fetchUserBusinessCardsConfigFailure = createAction(prefix + 'Fetch business cards config failure');

export const GlobalSettingsActions = {
    fetchMeetingBookingConfig,
    fetchMeetingBookingConfigSuccess,
    fetchMeetingBookingConfigFailure,
    fetchUserBusinessCardsConfig,
    fetchUserBusinessCardsConfigSuccess,
    fetchUserBusinessCardsConfigFailure
};
