import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule } from '@angular/router';
import { IsAuthenticatedGuard } from '@core/authentication/guards/is-authenticated/is-authenticated.guard';
import { DeeplinkNotificationsGuard } from '@core/notifications/deeplink-notifications.guard';
import { DeeplinkNotificationPageComponent } from '@core/notifications/pages/deeplink-notification-page/deeplink-notification-page.component';
import { CustomRoute } from '@core/routing/types/custom-route';
import { AppInitialisationGuard } from '../app-core/guards/app-initialisation.guard';
import { AppRedirectGuard } from '../app-core/guards/app-redirect.guard';
import { CompositeRouteGuard } from '../app-core/guards/composite.guard';
import { AppPageComponent } from '../app-core/pages/app-page/app-page.component';
import { AuthenticationGuard } from '../authentication/guards/authentication.guard';
import { AppFailedComponent } from '../error-handling/pages/app-failed/app-failed.component';
import { Error404Component } from '../error-handling/pages/error404/error404.component';
import { ModuleGuard } from '../module/guards/module.guard';
import { AppPageRoutes } from './constants/app-page-routes.constant';
import { AppRouteParams } from './constants/app-route-params.constant';
import { AppModuleRoutes } from './constants/module-routes.constant';
import { PageRoutes } from './constants/page-routes.constant';
import { AppUserPageRoutes } from './constants/user-page-routes.constant';
import { BadgesEnabledGuard } from './guards/badges-enabled/badges-enabled.guard';
import { BusinessCardsEnabledGuard } from './guards/business-cards-enabled/business-cards-enabled.guard';
import { MeetingBookingEnabledGuard } from './guards/meeting-booking-enabled/meeting-booking-enabled.guard';

const routes: CustomRoute[] = [
    {
        path: '',
        canActivate: [AppRedirectGuard],
        children: [
            {
                path: PageRoutes.container,
                loadChildren: () => import('../../features/container/container.module').then((m) => m.ContainerModule),
                data: {
                    title: { static: 'PAGE_TITLE_CONTAINER' }
                }
            },
            {
                path: `${PageRoutes.failedToLoad}/:${AppRouteParams.appName}`,
                component: AppFailedComponent,
                data: {
                    title: { static: 'PAGE_TITLE_APP_FAILED' }
                }
            },
            {
                // Usually guards run in parallel, this CompositeGuard runs the routeguards defined in 'data' in series.
                canActivate: [CompositeRouteGuard],
                data: { routeGuards: [AppInitialisationGuard, AuthenticationGuard] },
                path: `:${AppRouteParams.appName}`,
                component: AppPageComponent,
                children: [
                    {
                        path: '',
                        children: [
                            {
                                path: AppPageRoutes.error404,
                                component: Error404Component,
                                data: {
                                    title: { static: 'PAGE_TITLE_NOT_FOUND' }
                                }
                            },
                            {
                                path: AppPageRoutes.login,
                                loadChildren: () =>
                                    import('../../features/login/login.module').then((m) => m.LoginModule),
                                data: {
                                    title: { static: 'PAGE_TITLE_LOGIN' }
                                }
                            },
                            {
                                path: `${AppPageRoutes.deeplinkNotification}/:${AppRouteParams.deeplinkNotificationId}`,
                                canActivate: [IsAuthenticatedGuard, DeeplinkNotificationsGuard],
                                component: DeeplinkNotificationPageComponent
                            },
                            {
                                path: AppPageRoutes.pwaInstall,
                                loadChildren: () =>
                                    import('../../features/pwa-install/pwa-install.module').then(
                                        (m) => m.PwaInstallModule
                                    ),
                                data: {
                                    title: { static: 'PAGE_TITLE_DOWNLOAD' },
                                    focusMainOnNavigate: true
                                }
                            },
                            {
                                path: AppPageRoutes.verifyEmail,
                                loadChildren: () =>
                                    import('../../features/login/verify-email.module').then((m) => m.VerifyEmailModule),
                                data: {
                                    title: { static: 'PAGE_TITLE_VERIFY_EMAIL' }
                                }
                            },
                            {
                                path: AppPageRoutes.passwordReset,
                                loadChildren: () =>
                                    import('../../features/login/password-reset.module').then(
                                        (m) => m.PasswordResetModule
                                    ),
                                data: {
                                    title: { static: 'PAGE_TITLE_RESET_PASSWORD' }
                                }
                            },
                            {
                                path: AppPageRoutes.chat,
                                data: {
                                    focusMainOnNavigate: true,
                                    title: { static: 'PAGE_TITLE_CHAT' }
                                },
                                canActivate: [IsAuthenticatedGuard],
                                loadChildren: () => import('../../features/chat/chat.module').then((m) => m.ChatModule)
                            },
                            {
                                path: AppPageRoutes.company,
                                data: {
                                    focusMainOnNavigate: true,
                                    title: { fromSelector: 'companyDetail' }
                                },
                                loadChildren: () =>
                                    import('../../features/companies/company.module').then((m) => m.CompanyModule)
                            },
                            {
                                path: AppPageRoutes.challenge,
                                data: {
                                    focusMainOnNavigate: true,
                                    title: { fromSelector: 'challengeDetail' }
                                },
                                loadChildren: () =>
                                    import('../../features/gamification/challenge.module').then(
                                        (m) => m.ChallengeModule
                                    )
                            },
                            {
                                path: AppPageRoutes.infoPage,
                                data: {
                                    focusMainOnNavigate: true,
                                    title: { fromSelector: 'infoPageDetail' }
                                },
                                loadChildren: () =>
                                    import('../../features/info-pages/info-page.module').then((m) => m.InfoPageModule)
                            },
                            /* Customer HTML templates are using the old URL still - this is necessary to keep them working */
                            {
                                path: AppPageRoutes.infobooth,
                                redirectTo: AppPageRoutes.infoPage
                            },
                            {
                                path: AppPageRoutes.livePoll,
                                data: {
                                    focusMainOnNavigate: true,
                                    title: { fromSelector: 'livePollDetail' }
                                },
                                loadChildren: () =>
                                    import('../../features/live-polling/live-polling.module').then(
                                        (m) => m.LivePollingModule
                                    )
                            },
                            {
                                path: AppPageRoutes.liveStream,
                                data: {
                                    focusMainOnNavigate: true
                                },
                                loadChildren: () =>
                                    import('../../features/live-stream/live-stream.module').then(
                                        (m) => m.LiveStreamModule
                                    )
                            },
                            {
                                path: AppPageRoutes.map,
                                data: {
                                    focusMainOnNavigate: true,
                                    title: { fromSelector: 'mapDetail' }
                                },
                                loadChildren: () => import('../../features/map/map.module').then((m) => m.MapModule)
                            },
                            {
                                path: AppPageRoutes.note,
                                data: {
                                    focusMainOnNavigate: true,
                                    title: { fromSelector: 'noteDetail' }
                                },
                                canActivate: [IsAuthenticatedGuard],
                                loadChildren: () =>
                                    import('../../features/notes/notes.module').then((m) => m.NotesModule)
                            },
                            {
                                path: AppPageRoutes.person,
                                data: {
                                    focusMainOnNavigate: true,
                                    title: { fromSelector: 'peopleDetail' }
                                },
                                loadChildren: () =>
                                    import('../../features/people/people.module').then((m) => m.PeopleModule)
                            },
                            {
                                path: AppPageRoutes.qanda,
                                data: {
                                    focusMainOnNavigate: true
                                },
                                loadChildren: () =>
                                    import('../../features/qanda/qanda.module').then((m) => m.QandaModule)
                            },
                            {
                                path: AppPageRoutes.poll,
                                data: {
                                    focusMainOnNavigate: true,
                                    title: { fromSelector: 'pollDetail' }
                                },
                                loadChildren: () =>
                                    import('../../features/surveys/surveys.module').then((m) => m.SurveysModule)
                            },
                            {
                                path: `${AppPageRoutes.module}/:${AppRouteParams.moduleId}`,
                                canActivate: [ModuleGuard],
                                data: {
                                    title: { fromSelector: 'module' }
                                },
                                children: [
                                    {
                                        path: AppModuleRoutes.html,
                                        data: {
                                            focusMainOnNavigate: true
                                        },
                                        loadChildren: () =>
                                            import('../../features/html/html-module.module').then(
                                                (m) => m.HtmlModuleModule
                                            )
                                    },
                                    {
                                        path: AppModuleRoutes.portal,
                                        data: {
                                            focusMainOnNavigate: true
                                        },
                                        loadChildren: () =>
                                            import('../../features/portal/portal-module.module').then(
                                                (m) => m.PortalModuleModule
                                            )
                                    },
                                    {
                                        path: AppModuleRoutes.feed,
                                        data: {
                                            focusMainOnNavigate: true
                                        },
                                        loadChildren: () =>
                                            import('../../features/activity-feed/activity-feed-module.module').then(
                                                (m) => m.ActivityFeedModuleModule
                                            )
                                    },
                                    {
                                        path: AppModuleRoutes.agenda,
                                        data: {
                                            focusMainOnNavigate: true
                                        },
                                        loadChildren: () =>
                                            import('../../features/agenda/agenda.module').then((m) => m.AgendaModule)
                                    },
                                    {
                                        path: AppModuleRoutes.companies,
                                        data: {
                                            focusMainOnNavigate: true
                                        },
                                        loadChildren: () =>
                                            import('../../features/companies/companies-module.module').then(
                                                (m) => m.CompaniesModuleModule
                                            )
                                    },
                                    {
                                        path: AppModuleRoutes.document,
                                        data: {
                                            focusMainOnNavigate: true
                                        },
                                        loadChildren: () =>
                                            import('../../features/documents/documents-module.module').then(
                                                (m) => m.DocumentsModuleModule
                                            )
                                    },
                                    {
                                        path: AppModuleRoutes.challenge,
                                        data: {
                                            focusMainOnNavigate: true
                                        },
                                        loadChildren: () =>
                                            import('../../features/gamification/gamification-module.module').then(
                                                (m) => m.GamificationModuleModule
                                            )
                                    },
                                    {
                                        path: AppModuleRoutes.iframe,
                                        data: {
                                            focusMainOnNavigate: true
                                        },
                                        loadChildren: () =>
                                            import('../../features/iframe/iframe-module.module').then(
                                                (m) => m.IframeModuleModule
                                            )
                                    },
                                    {
                                        path: AppModuleRoutes.infoPage,
                                        data: {
                                            focusMainOnNavigate: true
                                        },
                                        loadChildren: () =>
                                            import('../../features/info-pages/info-page-module.module').then(
                                                (m) => m.InfoPageModuleModule
                                            )
                                    },
                                    /* Customer HTML templates are using the old URL still - this is necessary to keep them working */
                                    {
                                        path: AppModuleRoutes.infobooth,
                                        redirectTo: AppModuleRoutes.infoPage
                                    },
                                    {
                                        path: AppModuleRoutes.livepolling,
                                        data: {
                                            focusMainOnNavigate: true
                                        },
                                        loadChildren: () =>
                                            import('../../features/live-polling/live-polling-module.module').then(
                                                (m) => m.LivePollingModuleModule
                                            )
                                    },
                                    {
                                        path: AppModuleRoutes.livestream,
                                        data: {
                                            focusMainOnNavigate: true
                                        },
                                        loadChildren: () =>
                                            import('../../features/live-stream/live-stream-module.module').then(
                                                (m) => m.LiveStreamModuleModule
                                            )
                                    },
                                    {
                                        path: AppModuleRoutes.map,
                                        data: {
                                            focusMainOnNavigate: true
                                        },
                                        loadChildren: () =>
                                            import('../../features/map/map-module.module').then(
                                                (m) => m.MapModuleModule
                                            )
                                    },
                                    {
                                        path: AppModuleRoutes.people,
                                        data: {
                                            focusMainOnNavigate: true
                                        },
                                        loadChildren: () =>
                                            import('../../features/people/people-module.module').then(
                                                (m) => m.PeopleModuleModule
                                            )
                                    },
                                    {
                                        path: AppModuleRoutes.qanda,
                                        data: {
                                            focusMainOnNavigate: true
                                        },
                                        loadChildren: () =>
                                            import('../../features/qanda/qanda-module.module').then(
                                                (m) => m.QandaModuleModule
                                            )
                                    },
                                    {
                                        path: AppModuleRoutes.socialmedia,
                                        data: {
                                            focusMainOnNavigate: true
                                        },
                                        loadChildren: () =>
                                            import('../../features/social-media/social-media-module.module').then(
                                                (m) => m.SocialMediaModuleModule
                                            )
                                    },
                                    {
                                        path: AppModuleRoutes.survey,
                                        data: {
                                            focusMainOnNavigate: true
                                        },
                                        loadChildren: () =>
                                            import('../../features/surveys/surveys-module.module').then(
                                                (m) => m.SurveysModuleModule
                                            )
                                    },
                                    {
                                        path: AppModuleRoutes.widget,
                                        data: {
                                            focusMainOnNavigate: true
                                        },
                                        loadChildren: () =>
                                            import('../../features/widget/widget-module.module').then(
                                                (m) => m.WidgetModuleModule
                                            )
                                    },
                                    {
                                        path: AppModuleRoutes.loopin,
                                        data: {
                                            focusMainOnNavigate: true
                                        },
                                        loadChildren: () =>
                                            import('../../features/loopin/loopin-module.module').then(
                                                (m) => m.VirtualNetworkingModuleModule
                                            )
                                    },
                                    {
                                        path: AppModuleRoutes.videolibrary,
                                        data: {
                                            focusMainOnNavigate: true
                                        },
                                        loadChildren: () =>
                                            import('../../features/vod/vod-module.module').then(
                                                (m) => m.VodModuleModule
                                            )
                                    },
                                    {
                                        path: AppModuleRoutes.abstracts,
                                        data: {
                                            focusMainOnNavigate: true
                                        },
                                        loadChildren: () =>
                                            import('../../features/abstracts/abstracts.module').then(
                                                (m) => m.AbstractsModule
                                            )
                                    }
                                ]
                            },
                            {
                                path: `${AppPageRoutes.user}`,
                                children: [
                                    {
                                        path: AppUserPageRoutes.profile,
                                        data: {
                                            focusMainOnNavigate: true,
                                            title: { static: 'PAGE_TITLE_MY_PROFILE' }
                                        },
                                        canActivate: [IsAuthenticatedGuard],
                                        loadChildren: () =>
                                            import('../../features/user-profile/user-profile.module').then(
                                                (m) => m.UserProfileModule
                                            )
                                    },
                                    {
                                        path: AppUserPageRoutes.alerts,
                                        data: {
                                            focusMainOnNavigate: true,
                                            title: { static: 'PAGE_TITLE_ALERTS' }
                                        },
                                        loadChildren: () =>
                                            import('../../features/alerts/alerts.module').then((m) => m.AlertsModule)
                                    },
                                    {
                                        path: AppUserPageRoutes.chat,
                                        data: {
                                            focusMainOnNavigate: true,
                                            title: { static: 'PAGE_TITLE_CHATS' }
                                        },
                                        canActivate: [IsAuthenticatedGuard],
                                        loadChildren: () =>
                                            import('../../features/chat/user-chat-module.module').then(
                                                (m) => m.UserChatModule
                                            )
                                    },
                                    {
                                        path: AppUserPageRoutes.notes,
                                        data: {
                                            focusMainOnNavigate: true,
                                            title: { static: 'PAGE_TITLE_NOTES' }
                                        },
                                        canActivate: [IsAuthenticatedGuard],
                                        loadChildren: () =>
                                            import('../../features/notes/user-notes.module').then(
                                                (m) => m.UserNotesModule
                                            )
                                    },
                                    {
                                        path: AppUserPageRoutes.personalAgenda,
                                        data: {
                                            focusMainOnNavigate: true,
                                            title: { static: 'PAGE_TITLE_PERSONAL_AGENDA' }
                                        },
                                        canActivate: [IsAuthenticatedGuard],
                                        loadChildren: () =>
                                            import('../../features/personal-agenda/personal-agenda.module').then(
                                                (m) => m.PersonalAgendaModule
                                            )
                                    },
                                    {
                                        path: AppUserPageRoutes.surveys,
                                        data: {
                                            focusMainOnNavigate: true,
                                            title: { static: 'PAGE_TITLE_SURVEYS' }
                                        },
                                        loadChildren: () =>
                                            import('../../features/surveys/user-surveys.module').then(
                                                (m) => m.UserSurveysModule
                                            )
                                    },
                                    {
                                        path: AppUserPageRoutes.account,
                                        data: {
                                            focusMainOnNavigate: true,
                                            title: { static: 'PAGE_TITLE_ACCOUNT' }
                                        },
                                        canActivate: [IsAuthenticatedGuard],
                                        loadChildren: () =>
                                            import('../../features/user-account/user-account.module').then(
                                                (m) => m.UserAccountModule
                                            )
                                    },
                                    {
                                        path: AppUserPageRoutes.badge,
                                        data: {
                                            focusMainOnNavigate: true,
                                            title: { static: 'PAGE_TITLE_BADGE' }
                                        },
                                        canActivate: [IsAuthenticatedGuard, BadgesEnabledGuard],
                                        loadChildren: () =>
                                            import('../../features/user-badge/user-badge.module').then(
                                                (m) => m.UserBadgeModule
                                            )
                                    },
                                    {
                                        path: AppUserPageRoutes.badgeContacts,
                                        data: {
                                            focusMainOnNavigate: true,
                                            title: { static: 'PAGE_TITLE_BADGE_CONTACTS' }
                                        },
                                        canActivate: [IsAuthenticatedGuard, BadgesEnabledGuard],
                                        loadChildren: () =>
                                            import(
                                                '../../features/user-badge-contacts/user-badge-contacts.module'
                                            ).then((m) => m.UserBadgeContactsModule)
                                    },
                                    {
                                        path: AppUserPageRoutes.businessCards,
                                        data: {
                                            focusMainOnNavigate: true,
                                            title: { static: 'PAGE_TITLE_BUSINESS_CARDS' }
                                        },
                                        canActivate: [IsAuthenticatedGuard, BusinessCardsEnabledGuard],
                                        loadChildren: () =>
                                            import(
                                                '../../features/user-business-cards/user-business-cards.module'
                                            ).then((m) => m.UserBusinessCardsModule)
                                    },
                                    {
                                        path: AppUserPageRoutes.meetings,
                                        data: {
                                            title: { static: 'PAGE_TITLE_MEETINGS' }
                                        },
                                        canActivate: [IsAuthenticatedGuard, MeetingBookingEnabledGuard],
                                        loadChildren: () =>
                                            import('../../features/meeting-booking/meeting-booking.module').then(
                                                (m) => m.MeetingBookingModule
                                            )
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        path: '**',
                        component: Error404Component
                    }
                ]
            }
        ]
    }
];

const routerConfig: ExtraOptions = {
    paramsInheritanceStrategy: 'always',
    bindToComponentInputs: true
};

@NgModule({
    imports: [RouterModule.forRoot(routes, routerConfig)]
})
export class AppRoutingModule {}
