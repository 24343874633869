import { Injectable } from '@angular/core';
import { AppCoreFacadeService } from '@core/app-core/services/app-core-facade.service';
import { AuthenticationFacadeService } from '@core/authentication/services/authentication-facade.service';
import { MeetingBookingApiService } from '@features/meeting-booking/services/meeting-booking-api/meeting-booking-api.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, of, switchMap, withLatestFrom } from 'rxjs';
import { AppCoreActions } from '../../app-core/actions/app-core.actions';
import { GlobalSettingsActions } from '../actions/global-settings.actions';
import { UserBusinessCardsApiService } from '@features/user-business-cards/services/user-business-cards-api/user-business-cards-api.service';
import { AuthenticationApiActions } from '../../authentication/actions/authentication-api.actions';
import { MeetingBookingActions } from '@features/meeting-booking/store/actions/meeting-booking.actions';
import { UserBusinessCardsEditPageActions } from '@features/user-business-cards/store/actions/user-business-cards-edit-page.actions';

@Injectable()
export class GlobalSettingsEffects {
    public fetchMeetingBookingConfig$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                AppCoreActions.initialiseAppSuccess,
                AuthenticationApiActions.loginSuccess,
                MeetingBookingActions.enterPage
            ),
            switchMap(() => this.authenticationFacadeService.getAuthenticatedPerson()),
            withLatestFrom(this.appCoreFacadeService.getAppName()),
            switchMap(([_, appName]) =>
                this.meetingBookingApiService.getMeetingBookingConfig(appName).pipe(
                    map((res) => GlobalSettingsActions.fetchMeetingBookingConfigSuccess({ config: res })),
                    catchError(() => of(GlobalSettingsActions.fetchMeetingBookingConfigFailure()))
                )
            )
        )
    );

    public getAppBusinessCardSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                AppCoreActions.initialiseAppSuccess,
                AuthenticationApiActions.loginSuccess,
                AuthenticationApiActions.mfaValidateSuccess,
                UserBusinessCardsEditPageActions.enterPage
            ),
            withLatestFrom(this.appCoreFacadeService.getAppName()),
            switchMap(([_, appName]) =>
                this.authenticationFacadeService.hasAppAccessAndAuthenticated().pipe(
                    filter((authorised) => authorised),
                    switchMap(() => {
                        return this.userBusinessCardApiService.getAppSettings(appName).pipe(
                            map((settings) => GlobalSettingsActions.fetchUserBusinessCardsConfigSuccess({ settings })),
                            catchError(() => of(GlobalSettingsActions.fetchMeetingBookingConfigFailure()))
                        );
                    })
                )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private appCoreFacadeService: AppCoreFacadeService,
        private authenticationFacadeService: AuthenticationFacadeService,
        private meetingBookingApiService: MeetingBookingApiService,
        private userBusinessCardApiService: UserBusinessCardsApiService
    ) {}
}
